import { RelevantStatementsList } from './RelevantStatementsList'
import { FirebaseContext } from '/domain/FirebaseContext'

export default function RelevantStatementsListApp() {
  return (
    <FirebaseContext>
      <RelevantStatementsList />
    </FirebaseContext>
  )
}
