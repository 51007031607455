import { Loader } from '/features/buildingBlocks/Loader'
import { useUserSelectedStatements } from '/domain/useUserSelectedStatements'
import { routeMap } from '/routeMap'
import { MiniCard, MiniCardList } from '/features/buildingBlocks/MiniCard'
import { Icon } from '/features/buildingBlocks/Icon'
import { IconCheckmarkGreen, IconCheckmarkLightblue } from '/icons'

import styles from './RelevantStatementsList.css'

export function RelevantStatementsList() {
  const { isFetching, data } = useUserSelectedStatements()

  if (isFetching) return <Loader />
  const statements = Object.values(data)
  const minStatementsToFillIn = Math.min(4, statements.length)
  const filledInStatementsCount = statements.filter(statement => statement.form).length

  return (
    <div className={styles.component}>
      <MiniCardList>
        {statements.map(statement => {
          const hasFormValues = Boolean(statement.form)
          const icon = hasFormValues ? IconCheckmarkGreen : IconCheckmarkLightblue
          const href = routeMap.app.stepTwo.statement({ language: 'nl', id: statement.id })

          return (
            <MiniCard key={statement.id} tag='a' {...{ href }}>
              <div className={styles.content}>
                {statement.statement}
                <Icon {...{ icon }} />
              </div>
            </MiniCard>
          )
        })}
      </MiniCardList>
      {filledInStatementsCount < minStatementsToFillIn && (
        <p>Vul minimaal {minStatementsToFillIn} stellingen in</p>
      )}
    </div>
  )
}
